<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot class="header">
              <h5 class="modal-title">Merge {{ orgType }}?</h5>
            </slot>
            <button type="button" class="btn-close" data-dismiss="modal" @click="$emit('close')">
            </button>
          </div>
          <slot name="body">
            <div class="modal-body">
              <h6>This is irreversible. This {{ orgType }} will be merged.</h6>
              <span>Updated Information:</span>
              <span v-html="alertMessage"></span>
              <span>Type <span style="font-weight: bold;">MERGE {{ orgType.toUpperCase() }}S</span> to confirm</span>
              <div class="row">
                <div class="col-sm-6">
                  <input v-model="confirmMergeText" required/>
                </div>
              </div>
              <div style="text-align: center;">
                <button class="button btn-danger bi bi-link-45deg" :disabled="mergeButtonDisabled"
                        :style="mergeButtonStyle" @click="mergeHelper">Merge
                </button>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {ref, onBeforeMount, computed} from "vue";

export default {
  name: "OrgMergeModal",
  props: {
    orgData: Object,
    show: Boolean,
    orgType: String
  },
  setup(props, context) {
    const confirmMergeText = ref("");
    const orgToKeep = ref(props.orgData);

    function mergeHelper() {
      context.emit('mergeCall', true);
      context.emit('close');
    }

    const mergeButtonDisabled = computed(() => {
      return confirmMergeText.value !== `MERGE ${props.orgType.toUpperCase()}S`
    })

    const mergeButtonStyle = computed(() => {
      return mergeButtonDisabled.value ? "background-color: lightgrey; color: black;" : "";
    })

    const alertMessage = computed(() => {
      let alert_msg = "<p>";

      for (const prop in orgToKeep.value) {
        const split_text = prop.replace(/([A-Z])/g, " $1");
        let result = split_text.charAt(0).toUpperCase() + split_text.slice(1);
        let add_val = !prop.includes('id') && !prop.includes('Id') && orgToKeep.value[prop] !== null && !Array.isArray(orgToKeep.value[prop]) && !prop.includes('Count');
        if (add_val) {
          alert_msg += `<br/><span style="font-weight: bold;">${result}:</span> <span style="text-decoration: underline;">${orgToKeep.value[prop]}</span>`
        }
      }
      alert_msg += `</p>`

      return alert_msg;
    })

    return {
      confirmMergeText,
      mergeButtonDisabled,
      mergeButtonStyle,
      alertMessage,
      mergeHelper
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  /*z-index: 9998;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.modal-container {
  max-width: 800px;
  width: 80%;
  height: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow-y: scroll;
}


.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

input {
  border: 1px solid black;
}
</style>