<template>
  <div v-for="user in userData" :key="user" class="accordion-item ">
    <user-info :user="user" :show-edit-route="showEditRoute" :show-view-route="false"/>
  </div>
</template>

<script>
import {ref, onBeforeMount, watch, computed} from "vue";
import UserInfo from "@/components/UserOrganizationHierarchy/UserInfo";

export default {
  name: "UserOrganizationViewTemplate",
  components: {UserInfo},
  props: {
    userData: Array,
    viewingUserRole: Number,
    showEditRoute: Boolean
  },
  setup(props, context) {

    const count = computed(() => {
      return props.userData.length;
    })

    return {
      count
    }
  }
}
</script>

<style scoped>

</style>