<template>
  <div class="mt-2 ">
    <div>
      <router-link v-if="showEditRoute" :to="{name: 'EditUser', params: {idUser: userRouteId}}">{{ user.firstName }}
        {{ user.lastName }}
      </router-link>
      <router-link v-else-if="showViewRoute" :to="{name: 'ViewUser', params: {idUser: userRouteId}}">{{ user.firstName }}
        {{ user.lastName }}</router-link>
      <p v-else>{{ user.firstName }} {{ user.lastName }}</p>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "UserInfo",
  props: {
    user: Object,
    showEditRoute: Boolean,
    showViewRoute: Boolean
  },
  setup(props) {
    const userRouteId = computed(() => {
      return btoa(props.user.idUser);
    })

    return {
      userRouteId
    }
  }
}
</script>

<style scoped>

</style>