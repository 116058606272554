<template>
  <table style="margin: 10px; table-layout: auto;">
    <thead>
    <tr>
      <th style="color: black">Field Name</th>
      <th style="color: black">Content</th>
      <th style="color: black">Keep?</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(val, key) in displayData" :key="key">
      <td style="color: black">{{ val.label }}</td>
      <td style="color: black">
        <span :style="val.highlight ? 'background-color: yellow; text-decoration: underline;' : ''">{{ val.value ? val.value : "" }}</span>
      </td >
      <td style="color: black"><input v-if="val.highlight && !key.includes('id')" type="radio" :value="val.value ? val.value : ''" :name="key"
                 @change="selectValue($event, key)"
                 :id="`${key}-${indexIdValue}`" :checked="radioChecked" style="width: 100%;"/></td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {onBeforeMount} from "vue";

export default {
  name: "MergeObjectDisplay",
  props: {
    displayData: Object,
    indexIdValue: Number,
    radioChecked: Boolean
  },
  setup(props, context) {

    onBeforeMount(() => {
    })

    function selectValue(event, key) {
      let emit_obj = {
        key: key,
        value: event.target.value
      };
      context.emit("valChanged", emit_obj);
    }

    return {
      selectValue
    }
  }
}
</script>

<style scoped>
label {
  text-transform: none;
  font-weight: normal;
  /*display: inline-block;*/
}

th {
  border: 1px solid rgb(0, 0, 0);

}

td {
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  height: 50px;
  padding: 2px;
  border: 1px solid rgb(0, 0, 0);
  word-wrap: anywhere;
  /*overflow-x: scroll;*/

}
/*span {*/
/*  border: 1px solid slateblue;*/
/*}*/
</style>