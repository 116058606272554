<template>
  <div v-if="!isLoading">
    <UserOrganizationViewTemplate :user-data="users" :viewing-user-role="authUser.position.roleHierarchy" :show-view-route="showViewRoute" :show-edit-route="false"/>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onBeforeMount, ref, watch} from "vue";
import axios from "axios";
import {getUsersByOrganizationAndPosition} from "@/Helpers/ApiCalls/GetUserCalls";
import UserOrganizationViewTemplate from "@/components/UserOrganizationHierarchy/UserOrganizationViewTemplate";
import {API_URL} from "../../../Constants";

export default {
  name: "UserHierarchyList",
  components: {
    UserOrganizationViewTemplate
  },
  props: {
    orgType: Number,
    idOrganization: Number,
    roleHierarchy: Number
  },
  setup(props) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const authUser = store.getters['auth/user'];
    const users = ref([]);
    const isLoading = ref(true);
    const viewingUser = ref({});

    onBeforeMount(() => {
      getViewingUser();
      if (props.idOrganization){
        getUsers();
      }
    })

    watch(() => props.orgType, () => {
      getUsers();
    })

    watch(() => props.roleHierarchy, () => {
      getUsers();
    })

    watch(() => props.idOrganization, () => {
      getUsers();
    })

    async function getUsers() {
      isLoading.value = true;
      await axios.get(getUsersByOrganizationAndPosition(), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        },
        params: {
          _orgType: props.orgType,
          _idOrganization: props.idOrganization,
          _roleHierarchy: props.roleHierarchy
        }
      }).then((result) => {
        users.value = result.data.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
      }).finally(() => {
        isLoading.value = false;
      })
    }

    async function getViewingUser() {
      let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(authUser.idUser);
      await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        viewingUser.value = result.data;
      })
    }

    const showViewRoute = computed(() => {
      const role_cleared = viewingUser.value.roleHierarchyId < props.roleHierarchy;
      let org_cleared = false;

      switch (props.orgType) {
        case 1:
          org_cleared = viewingUser.value.idEsu !== null && viewingUser.value.idEsu === props.idOrganization;
          break;
        case 2:
          org_cleared = viewingUser.value.idDistrict !== null && viewingUser.value.idDistrict === props.idOrganization;
          break;
        case 3:
          org_cleared = viewingUser.value.idSchool === props.idOrganization;
          break;
        case 4:
          org_cleared = viewingUser.value.idOutstateOrganization === props.idOrganization;
          break;
      }

      if (viewingUser.value.roleHierarchyId === 1) {
        org_cleared = true;
      }

      return role_cleared && org_cleared;
    })

    return {
      users,
      isLoading,
      authUser,
      showViewRoute
    }
  }
}
</script>

<style scoped>

</style>